/* Custom Cursor */
.cursor-pointer {
    cursor: pointer !important;
}

/* Custom Button */
.btn-close, .btn-close:hover {
    background: red !important;
    color: white;
    font-size: 24px !important;
    border-radius: 50%;
    padding: 4px !important;
    margin-right: 0.1px !important;
    box-shadow: 2px solid red;
}

/* Custom Border */
.border-none {
    border: none !important;
}

/* Custom Text */
.text-hover-underline:hover {
    text-decoration: underline !important;
    text-underline-offset: 2px !important;
}

/* Custom Table */
.table-md thead th,
.table-md thead td,
.table-md tbody th,
.table-md tbody td {
    padding: 0.5rem;
}
.table-x-md thead th,
.table-x-md thead td,
.table-x-md tbody th,
.table-x-md tbody td {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}
.table-y-md thead th,
.table-y-md thead td,
.table-y-md tbody th,
.table-y-md tbody td {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}
.table-bordered-black th,
.table-bordered-black td {
    border-color: gray !important;
}

/* Custom Background */
.bg-password {
    background: rgb(44, 44, 131);
}
.bg-auth {
    background: rgba(47, 47, 97, 0.788);
}

/* Custom Dropdown */
.dropdown-toggle::after {
    display: none;
}
.dropdown-item:hover {
    background-color: #3894caf3 !important;
    color: white !important;
}

/* Custom Invalid Input */
.invalidInput {
    border-color: #f34343 !important;
}
.invalidInput:focus {
    border-color: #f34343 !important;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

/* Custom Toast */
.Toastify__toast-container {
    width: 420px !important;
    z-index: 100000000 !important;
}
.Toastify__toast {
    z-index: 100000000 !important;
}

/* Custom Re-captcha */
.g-recaptcha {
    transform: scale(1.23, 0.85);
    transform-origin: 0;
}

@media only screen and (max-width: 600px) {
    .g-recaptcha {
        transform: scale(0.82);
        transform-origin: 0;
    }
}

/* Custom Dashboard */
/* .circle-style {
    border: 2px dotted #282f53 !important;
} */
.dashboard-scale {
    background-color: initial;
    transition: background-color 0.4s ease;
}
.dashboard-scale:hover {
    background-color: #d9def3ec;
    /* color: whitesmoke !important; */
    cursor: pointer;
}
.inbox-item-highlight:hover {
    box-shadow: inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0, 0 1px 2px 0 rgba(60, 64, 67, .3), 0 1px 3px 1px rgba(60, 64, 67, .15);
    z-index: 2;
}

/* Custom Table Action */
.tableActionBtn {
    font-size: 15px;
    padding: 5px 10px;
    font-weight: 500;
}
.tableDelete {
    background: red !important;
    border: 1px solid red;
}
.tableDelete:hover {
    background: red !important;
    border: 1px solid red;
}
.tablePermission {
    background: rgb(16, 191, 214) !important;
}
.tablePermission:hover {
    background: rgb(16, 191, 214) !important;
}
.menuPermission {
    background: rgb(5, 101, 226) !important;
}
.menuPermission:hover {
    background: rgb(5, 101, 226) !important;
}
.newBtnAction, .newBtnAction:hover {
    background: #33448c !important;
}

/* Custom Table Scrollbar */
.list-scroll {
    max-height: 300px;
    overflow-y: scroll;
}
.revenue-list-scroll {
    max-height: 500px;
    overflow-y: scroll;
}
.list-scroll-500 {
    max-height: 500px;
    overflow-y: scroll;
}
.list-scroll::-webkit-scrollbar,
.list-scroll-500::-webkit-scrollbar {
    width: 8px;
}
.list-scroll::-webkit-scrollbar-track,
.list-scroll-500::-webkit-scrollbar-track {
    background: #f1f1f1;
}
.list-scroll::-webkit-scrollbar-thumb,
.list-scroll-500::-webkit-scrollbar-thumb {
    background: var(--primary-bg-color) !important;
    border-radius: 5px;
    border: 2px solid var(--primary-bg-color) !important;
}

/* Custom Side Menu */
.side-menu .slide .side-menu__item.active {
    background-color: #33448c !important;
    color: #fff !important;
    border-right: 3px solid #33448c !important;
    box-shadow: 1px 1px 1px 1px #33448c !important;
}
.side-menu .slide .side-menu__item.active .side-menu__icon,
.side-menu .slide .side-menu__item.active .side-menu__label,
.side-menu .slide .side-menu__item.active .angle {
    color: #fff !important;
    font-weight: 600 !important;
}
.side-menu__item.active,
.side-menu__item:hover,
.side-menu__item:focus {
    text-decoration: none !important;
    color: #fff !important;
}
.side-menu__item.active .side-menu__label,
.side-menu__item.active .side-menu__icon,
.side-menu__item:hover .side-menu__icon,
.side-menu__item:focus .side-menu__icon,
.side-menu__item.active .angle {
    color: #fff !important;
}
.slide:hover .side-menu__label,
.slide:hover .angle,
.slide:hover .side-menu__icon {
    color: #33448c !important;
    fill: #33448c !important;
    font-weight: 600 !important;
}
.dark-theme .slide:hover .side-menu__label,
.dark-theme .slide:hover .angle,
.dark-theme .slide:hover .side-menu__icon {
    color: #fff !important;
    fill: #fff !important;
}
.slide-item {
    font-weight: 600 !important;
}
.slide-item.active,
.slide-item:hover,
.slide-item:focus {
    text-decoration: none !important;
    color: #33448c !important;
    font-weight: 600 !important;
}
.dark-theme .slide-item:hover,
.dark-theme .slide-item.active,
.dark-theme .slide-item:focus {
    text-decoration: none !important;
    color: #fff !important;
    font-weight: 600 !important;
}
.slide-item .app-sidebar .slide-menu .slide-item:before:hover {
    color: #33448c !important;
}
.slide.is-expanded .slide-menu a:hover:before {
    color: #33448c !important;
}
.app-sidebar .slide-menu a.active:before {
    color: #33448c !important;
}
.dark-theme .slide-item .app-sidebar .slide-menu .slide-item:before:hover {
    color: #fff !important;
}
.dark-theme .slide.is-expanded .slide-menu a:hover:before {
    color: #fff !important;
}
.dark-theme .app-sidebar .slide-menu a.active:before {
    color: #fff !important;
}

/* Custom Modal Size */
.modal-60-vw,
.modal-70-vw,
.modal-80-vw,
.modal-90-vw {
    max-width: none !important;
}

@media (min-width: 576px) {
    .modal-60-vw,
    .modal-70-vw,
    .modal-80-vw,
    .modal-90-vw {
        margin: 1.75rem;
    }
}

@media (min-width: 992px) {
    .modal-60-vw {
        width: 60vw !important;
        min-width: 1000px !important;
        max-width: 1100px !important;
        margin: 1.75rem auto;
    }
}

@media (min-width: 1200px) {
    .modal-70-vw {
        width: 70vw !important;
        min-width: 1100px !important;
        max-width: 1200px !important;
        margin: 1.75rem auto;
    }
    .modal-80-vw {
        width: 80vw !important;
        min-width: 1100px !important;
        max-width: 1200px !important;
        margin: 1.75rem auto;
    }
    .modal-90-vw {
        width: 90vw !important;
        min-width: 1200px !important;
        max-width: 1600px !important;
        margin: 1.75rem auto;
    }
}

/* Custom Receipt Header */
.parent {
    width: 100%;
    height: 1300px;
    position: relative;
}
.parent:after {
    content: '';
    background: url('./assets/img/background.png');
    width: 100%;
    height: 100%;
    position: absolute;
    background-position: center;
    background-blend-mode: overlay;
    background-size: cover;
    top: 0;
    left: 0;
    opacity: 0.6;
}
.parent-non-revenue {
    width: 100%;
    height: 1300px;
    position: relative;
}
.parent-non-revenue:after {
    content: '';
    background: url('./assets/img/non-revenue-background.png');
    width: 100%;
    height: 100%;
    position: absolute;
    background-position: center;
    background-blend-mode: overlay;
    background-size: cover;
    top: 0;
    left: 0;
    opacity: 0.2;
}
.child {
    position: relative;
    z-index: 1;
}

/* Custom Watermark */
.text-water-mark {
    transform: rotate(331deg);
    font-size: 80px;
    color: rgba(183, 183, 183, 0.17);
    position: absolute;
    text-transform: capitalize;
    top: 40%;
    font-weight: 500;
}


/* Custom Voucher */
.voucher-logo {
    margin-left: -100px;
}

/* Custom Stepper */
.stepper {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 40px;
}
.stepper .stepper-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    width: 130px;
}
.stepper :not(:last-child) .stepper-icon ::after {
    content: "";
    border: 2px solid #ced4da;
    position: absolute;
    right: -130px;
    top: 50%;
    width: 130px;
}
.stepper .stepper-icon {
    border-radius: 50%;
    background-color: #ced4da;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    color: white;
    font-size: 20px;
}
.stepper-item.stepper-completed .stepper-icon, .stepper-item.stepper-completed .stepper-icon ::after {
    background-color: #259952;
    border: 2px solid #259952;
}
.stepper-item.stepper-current .stepper-icon, .stepper-item.stepper-current .stepper-icon ::after {
    background-color: #0dcaf0;
    border: 2px solid #0dcaf0;
}
.custom-wd-5p {
    width: 2%;
  }
.border-x-content{
    border-top: 2px solid rgb(248, 248, 248);
    border-bottom: 2px solid rgb(248, 248, 248);
}
.bg-dark-600{
    background: rgb(128, 128, 128);
}

@media print {
    .a5-landscape-print {
        transform: rotate(90deg);
        /* height top-bottom placement */
        margin-top: 410px;
        /* height top-bottom length */
        margin-right: -380px;
        /* right spacing */
        padding-top: 200px;
    }
    .a5-landscape-print p,
    .a5-landscape-print th,
    .a5-landscape-print td {
        font-size: 20px !important;
    }
}

.report-page {
    min-height: calc(100vh - 200px);
    border: 1px solid black;
    margin: 40px;
    padding: 40px;
}
@media print {
    .report-page {
        min-height: 100vh;
        padding: 40px;
        margin: 0px 0px 0px 20px;
        border: none;
    }
}